<template>
  <div class="d-flex h-100 justify-content-center">
    <div class="d-flex  align-items-center mh-100 mw-100">

      <b-container >
        <b-row>
          <b-col>
            <p class="text-center">
                {{ textsVariables.welcome.choose_feeling }}
            </p>
          </b-col>
        </b-row>

        <!-- TODO: CHANGE E.IMAGE TO E.IMAGE-ACTIVE OR IMAGE-DISABLED -->
        <b-row class="mx-1">
          <b-col v-for="(e,index) of survey_icon"
          :key="index"
          class="px-0">
            <div v-if="e.id == answeredSurvey.answerQuestion1" class="p-2">
                <img
                    class="emoticon" 
                    @click = "setEmojiId(e.id)"
                    :src="getImgUrl(e.image_check)"
                >
            </div>
            <div v-else class="p-2">
                <img 
                    class="emoticon" 
                    @click = "setEmojiId(e.id)"
                    :src="getImgUrl(e.image)"
                >
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col>
            <p v-if="this.selectedvalue === true" class="text-left">
                {{ textsVariables.welcome.comment_survey }}
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div v-if="this.selectedvalue === true" style="margin-bottom:65%">
              <div>
                <div  v-bind:class="{ 'active-form-text': this.validationAnswerQuestion2==true, 'disabled-form-text': this.validationAnswerQuestion2==false }">
                  <b-form-textarea 
                  class="remove-border-form-text" 
                  id="commentText"                   
                  v-model="answeredSurvey.answerQuestion2"
                  :placeholder="$t('survey_comment_validation')"
                  rows="5"
                  no-resize
                  
                  ></b-form-textarea>
                </div>
              </div>
              <div>
              <br>
              <br>
              
              </div>
            </div> 
          </b-col>
        </b-row>

        
      </b-container>

      <div class="fixed-bottom mb-5" >
        <div v-if="this.selectedvalue" style="width: 300px; margin: 0 auto;">
          <p class="custom-message" >{{ textsVariables.welcome.warning }}</p>
        </div>
        <div class="d-flex justify-content-center">
        
          <div>
            
            <router-link to="/end"><b-button
                
              
              v-bind:disabled="this.selectedvalue === false || this.validationAnswerQuestion2==false"
              class="px-5"
              v-bind:class="{ 'active-button': this.selectedvalue, 'disabled-button': !this.selectedvalue }"
              @click = "sendSurvey"
              
              >
                {{ $t('submit') }}
              </b-button></router-link>
            </div>
        </div>

      </div>
    </div>
  </div>


  
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { osName } from 'mobile-device-detect';
import Constants from '../utils/constants';
import Service from '../services/service';
import DOMPurify from 'dompurify';

export default {
  props: {
    
  },
  data() {
    return {
        build: true,
        setup: true,
        selectedvalue: false,
        answeredSurvey:{
          answerQuestion1: '', 
          answerQuestion2:'',
          printer:'',
          device: osName,
          surveyLanguage: ''
        },
        errors:[]
    };
  },
  mounted() {
    this.setInit();
    let self = this;
    setTimeout(function(){
      self.build = false;
      setTimeout(function(){
        self.setup = false; 
      }, 1000);
    }, 2000);
  },
  computed: {
    survey_icon(){
      return this.$t(Constants.survey_icon);
    },
    textsVariables: function() {
      return this.printer_data(this);
    },
    validationAnswerQuestion2(){
      if(this.answeredSurvey.answerQuestion2.length >=0 && this.answeredSurvey.answerQuestion2.length <=200) return true;
      else if (this.answeredSurvey.answerQuestion2.length == 0) return true;
      return false;
    },
    ...mapGetters(["printer", "printer_data","welcome"])
  },
  methods:{
    setInit(){
      this.answeredSurvey.surveyLanguage = this.$i18n.locale;
      this.answeredSurvey.printer = this.printerName();
    },
    getImgUrl(pic) {
        return require('@/assets/img/surveyicons/'+pic)
    },
    printerName: function(){
      var printer = this.printer_data(this);
      return printer.name;
    },
    setEmojiId: function(id){
      this.answeredSurvey.answerQuestion1 = id;
      this.selectedvalue = true;
     },
     sendSurvey: async function(){
      this.answeredSurvey.answerQuestion2=this.sanitizeEmails(this.answeredSurvey.answerQuestion2);
      /*alert(this.answeredSurvey.answerQuestion2);*/
      try {
        await Service.methods.postEndpointAsync("/SaveSurvey",this.answeredSurvey);
      } catch (error) {
        this.errors.push(error);
      }
    },
    sanitizeEmails(text) {
      const charactersToRemove = /<|>|%|"|'|#|\*|\(|\)/g;
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
      const phoneRegex = /\b\d(?:\s*\d){6,}\b/g;
      text=text.replace(emailRegex, "PERSONAL_DATA_EMAIL")
      text=text.replace(phoneRegex, "PERSONAL_PHONE_NUMBER")
      return (DOMPurify.sanitize(text).replace(charactersToRemove,""));

    }
  }
};
</script>
<style lang="scss" scoped>
@media (pointer: coarse) and (max-width: 370px) {
  .btn{
    font-size: 12px !important;
  }
  .text-center{
    font-size: 14px !important;
  }
  .text-left{
    font-size: 14px !important;
  }
  .emoticon{
  height: 40px !important;
  max-width: 40px !important;

}
}
.emoticon{
  height: 50px;
  max-width: 50px;

}
.active-button {
  width: 100%;
  background-color: $hp-blue !important;
  border-color: $hp-blue !important;
}
.disabled-button {
  width: 100%;
  background-color: $hp-gray !important;
  border-color: $hp-gray !important;
}


.remove-border-form-text{
  border: 10px !important;
  outline: 0 !important;
  font-size:small !important;
}

.active-form-text {
  border-left: 6px solid $hp-blue;
}
.disabled-form-text {
  border-left: 6px solid $hp-gray;
}
textarea:focus
{
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
}
#commentText{
  font-family: Forma-Light;
}

.custom-message {
    font-size: 14px;
    color: #888;
}

</style>