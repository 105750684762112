<template>
  <div class="d-flex flex-grow-1 flex-wrap">
    <div class="d-flex align-items-center flex-column pt-4 col-12 px-3">
      <SummaryPoints /> 
      <SummaryButton class="mt-auto" />
    </div>
  </div>
</template>

<script lang="ts">
import SummaryPoints from './views/SummaryPoints.vue';
import SummaryButton from './views/SummaryButton.vue';

export default {
    components: {
        SummaryPoints,
        SummaryButton
    }
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 991px) { /* mobile */
  .text-Steps{
    font-family: Forma-Regular, Arial;
    font-size: 15px;
  }
}
</style>