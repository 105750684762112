import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import router from "./router";
import Route from "vue-router";
import i18n from "./plugins/i18n";
import store from "./store"
import vClickOutside from 'v-click-outside'
import VueZoomer from 'vue-zoomer'
import VueGtag from "vue-gtag"

Vue.config.productionTip = true;
Vue.use(BootstrapVue);
Vue.use(Route);
Vue.use(Vuex);
Vue.use(vClickOutside);
Vue.use(VueZoomer);
Vue.use(VueGtag, {config: { id: "G-8KQ1XNP9ZX" }}, router);

new Vue({
    el: '#app',    
    router,
    store,
    i18n,
    render: h => h(App),    
}).$mount("#app");

//router.replace('/')