<template>
  <div class="px-3 d-flex flex-column">
    <div class="title-Steps pt-4 px-3">{{ textsVariables.welcome.titleSumary }}</div>
    <template v-for="(chapter, index) in chapters">
        <div 
        class="d-flex align-items-center ml-3 summarypoints-chapter-text-container"
        :key="'chapter_' + index"
        >
            <p class="summarypoints-chapter-text">
              {{ chapter.chapter }}
            </p>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { mapGetters } from "vuex";
import Constants from "../../utils/constants.js";
export default {
    computed: {
        ...mapGetters(["printer", "version", "printer_data"]),
        chapters: function() {
            return this.printer_data(this).chapters.filter(chapter => 
                !("version" in chapter) || this.version == chapter.version)
        },
         textsVariables: function() {
            return this.printer_data(this)
        }
    },
    methods: {
        beforeEnter: function(el) {
            el.style.opacity = 0;
        },
        enter: function(el) {
            var delay = el.dataset.index * (Constants.chapterDotDelay * 3)
            if("dotIndex" in el.dataset) delay += Constants.chapterDotDelay*el.dataset.dotIndex
            setTimeout(function() {
                el.style.animationName = "pop-animation";
                el.style.animationDuration = (Constants.chapterDotDelay/1000) + "s";
                el.style.animationTimingFunction = "ease-in-out"
                el.style.WebkitAnimationTimingFunction = "ease-in-out"
            }, delay);
        }
    }

}
</script>

<style lang="scss" scoped>
.summarypoints-item {
    height: 2.5rem;
}
.summarypoints-chapter-indicator {
    margin: 0rem 0.1rem;
    width: 2rem;
    min-width: 2rem;
    color: $hp-blue;
    border-radius: 25px;
    border: $hp-blue;
    background: white;
    border-style: solid;
    height: 2rem;
    animation-fill-mode: forwards;
}
.summarypoints-chapter-text {
    text-align: left;
    margin-bottom: 0 !important;
    font-size: 16px
}
.summarypoints-chapter-text-container {
    animation-fill-mode: forwards;
    border-left-color: $hp-medium-gray;
    border-left-style: solid;
    padding-left: 10px;
    margin: 10px;
}
.summarypoints-small-dot-container {
    width: 2rem;
    height: 0.5rem;
    animation-fill-mode: forwards;
}

.summarypoints-small-dot{
    width: 0.5rem;
    height: 0.5rem;
    margin: 0rem 0.1rem;
    background-color: $hp-blue;
    border-radius: 50%;
}

.title-Steps{
  font-family: Forma-Regular, Arial;
  font-size: 26px;
  text-align: left;
}

@media only screen and (max-width: 991px) { /* mobile */
    .summarypoints-chapter-text {
        font-size: 15px;
    }
}

</style>