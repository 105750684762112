import Router from "vue-router";
import Landing from "./components/Landing.vue";
import Steps from "./components/Steps.vue";
import End from "./components/End.vue";
import Survey from "./components/Survey.vue";

export default new Router({
    mode: "history", 
    routes: [
        {
            path: "/",
            component: Landing,
            meta: {
                title: "Welcome to OOBE"
            }
        },
        {
            path: "/steps",
            component: Steps,
            meta: {
                title: "Welcome to OOBE - Steps"
            }
        },
        {
            path: "/end",
            name: "end",
            component: End,
            meta: {
                title: "Welcome to OOBE - End"
            }
        },
        {
            path: "/survey",
            name: "survey",
            component: Survey,
            meta: {
                title: "Welcome to OOBE - Survey"
            }
        },
        {
            path: "/*",
            name: "notFound",
            component: Landing,
            meta: {
                title: "Welcome to OOBE - OOBE"
            }
        }

        
    ]
    ,linkActiveClass: 'active'
});