<template>
  <div class="justify-content-center"
    :class="{'d-flex': !hidden, 'invisible': hidden}">
    <div>
        <div class="px-2 chapter-image mb-1 d-flex justify-content-center align-items-center">
          <img 
            :src="require('@/assets/img/' + printer + '/' + textVariables.welcome.chapters_image )"
            class="chapter-img"
          >
          <div class="summarybutton-text-container pl-3">
            <p>
              {{ textVariables.welcome.chapters_image_text }}
            </p>
          </div>
        </div>
      <div class="px-3">
          <b-button
            variant="primary"
            to="/steps"
            class="primary-button-hp text-center next-button mb-3"
          >
            {{ $t("next") }}
          </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import Constants from "../../utils/constants.js";
export default {
    props: {
        hidden: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    computed: {
        ...mapGetters(["printer", "printer_data"]),
        textVariables: function() {
            return this.printer_data(this)
        }
    },
    methods: {
        beforeEnter: function(el) {
            el.style.opacity = 0;
        },
        enterButton: function(el) {
            var delay = this.textVariables.chapters.length * (Constants.chapterDotDelay * 3);
            setTimeout(function() {
                el.style.animationName = "pop-animation";
                el.style.animationDuration = (Constants.chapterDotDelay/1000) + "s";
                el.style.animationTimingFunction = "ease-in-out"
                el.style.WebkitAnimationTimingFunction = "ease-in-out"
            }, delay);
        }
    }
}
</script>

<style lang="scss" scoped>
.chapter-image {
    animation-fill-mode: forwards;
    display: table;
}

.chapter-img{
    display: table-cell;
    vertical-align: middle;
    max-width: 5rem;
}

.summarybutton-text-container {
    display: table-cell;
    vertical-align: middle;
}

.summarybutton-text-container > p {
    font-size: 15px;
    text-align: left;
    margin-bottom: 0 !important;
}

.next-button {
    background-color: $hp-blue;
    border-color: $hp-blue !important;
    animation-fill-mode: forwards;
    width: 40%;
    min-width: 300px;
    min-height: 50px;
    line-height:3;

}

.next-button:hover {
    background-color: $hp-blue-hover;
    border-color: $hp-blue !important;
}

.next-button:focus {
    background-color: $hp-blue-hover !important;
    border-color: $hp-blue !important;
}
@media only screen and (max-width: 991px) { /* mobile */
  .summarybutton-text-container > p {
    font-size: 14px;
  }
  .next-button {
    font-size: 14px;
  }
}

</style>