<template>
  <div class="h-100 d-flex flex-column"> 
  
    <language/>
    <component
      :is="currentComponent"
      @next="nextScreen()"
      @chosen="versionChosen()"
    />
  </div>
</template>

<script lang="ts">
import Language from "./Language.vue";
import PrinterAnimation from "./PrinterAnimation.vue";
import SummaryMobile from "./SummaryMobile.vue";
import Version from "./Version.vue";
import { mapGetters } from "vuex";  


export default {
    components: {
        Language, PrinterAnimation, SummaryMobile, Version
    },
    data() {
        return { 
            currentComponent: 'PrinterAnimation',
            isVersionChosen: false
        };
    },
    computed: {
        //...mapGetters(["printer", "printer_data"])
        ...mapGetters(["printer", "version", "printer_data"])
    },
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.hp.com/cma/ng/lib/exceptions/privacy-banner.js')
      document.head.appendChild(recaptchaScript)
    },
    methods: {
        goToNextPage() {
            this.$router.push({ path: "chapters" });
        },
        nextScreen() {
            if ("versions" in this.printer_data(this) && this.printer_data(this).versions.length > 1 && !this.isVersionChosen)
                this.currentComponent = 'Version'
            else
                this.currentComponent = 'SummaryMobile'
        },
        versionChosen() {
            this.isVersionChosen = true;
            this.nextScreen();
        }
    } 
};
</script>
