import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Constants from './utils/constants'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        printer: '',
        version: '',
        languageLocale: ''
    },
    mutations: {
        updatePrinter(state, printer) {
            state.printer = printer;
        },
        updateVersion(state, version) {
            state.version = version;
        },
        updateLanguageLocale(state, languageLocale) {
            state.languageLocale = languageLocale;
        }
    },
    getters: {
        printer: state => {
            return state.printer;
        },
        version: state => {
            return state.version;
        },
        welcome: state => {
            return state.welcome;
        },
        printer_data: state => (self) => {        
            return self.$t(Constants.printers).find(x => x.code.toLowerCase() === state.printer.toLowerCase());
        },
        languageLocale: state => {
            if (state.languageLocale != '')
                return state.languageLocale
            else {
                let locale = navigator.language;
                return locale.split("-")[0]
            }
        }
    },
    actions: {
        readPrinter (context, me) {
            if ("printer" in me.$route.query) {
                context.commit('updatePrinter', me.$route.query.printer)
                context.dispatch('setDefaultVersion', me);
            }
        },
        setDefaultVersion (context, me) {
            let printerVariables = context.getters.printer_data(me)
            if ("versions" in printerVariables && printerVariables.versions.length > 0) {
                context.commit('updateVersion', printerVariables.versions[0])
            } else {
                context.commit('updateVersion', '')
            }
        }
    },
    plugins: [createPersistedState()]
});