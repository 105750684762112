import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "../translations";

Vue.use(VueI18n);

let locale = navigator.language;
export default new VueI18n({
    locale: locale.split("-")[0], // set locale
    fallbackLocale: "en", // set fallback locale
    messages // set locale messages
});
