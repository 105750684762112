<template>
<!--
  <div class="d-flex flex-column align-items-center justify-content-center h-100 px-1 py-3">
      <img
          :src="require('@/assets/img/End_screen.gif')"
         class="position-absolute"
        top="50%" left="50%" style="transform: translate(0%, 5%);"
        />
  </div> -->
  <div class="d-flex flex-column align-items-center justify-content-center h-100 px-1 py-3">
      <img
          :src="require('@/assets/img/last_screen/' +  textVariables.welcome.last_screen)"
         class="position-absolute"
        top="50%" left="50%" style="transform: translate(0%, 5%);"
        />
  </div>
  
</template>

<script lang="ts">
import { mapGetters } from "vuex";
export default {
  data() {
    return {
        build: true,
        setup: true
    };
  },
  mounted() {
    let self = this;
    setTimeout(function(){
      self.build = false;
      setTimeout(function(){
        self.setup = false; 
      }, 1000);
    }, 2000);
  },
  computed: {
    ...mapGetters(["printer", "printer_data"]),
    textVariables: function() {
      return this.printer_data(this);
    },
    
  }  
};
</script>
<style lang="scss" scoped>
.image {
  height: 100%;
  max-width: 100%;
}
</style>
